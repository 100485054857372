<script setup lang="ts">
const { t, locale } = useI18n()
import PrimaryHeader from '@/components/layout/header.vue'
import PrimaryFooter from '@/components/layout/footer.vue'
const url = useRequestURL().origin + useRequestURL().pathname

useHead(useNuxtApp().$getMetaTags(
    t('meta.title'),
    t('meta.description'),
    url
)
)
</script>

<template>
    <div>
        <PrimaryHeader />
        <div class="layout">
            <slot />
        </div>
        <PrimaryFooter />
        <CookieControl :locale="locale" />
    </div>
</template>

<style lang="scss" scoped>
.layout {
    @apply mt-[88px];
}
</style>