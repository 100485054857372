<script setup lang="ts">
const { t, locale, setLocale } = useI18n()

const links = computed(() => {
    return [
        { label: t("navigation.about"), link: 'about' },
        { label: t("navigation.features"), link: 'features' },
        { label: t("navigation.pricing"), link: 'pricing' },
    ]
})

defineExpose({ links })

const lang = useCookie('lang')

const isOpened = ref(false)
const selectedLocale = ref<{ label: string, value: string } | null>(null)

const availableLocales = computed(() => {
    const locales = ['hr', 'en', 'us', 'rs', 'ba', 'mk']
    return locales.map((locale) => {
        
        return {
            label: '',
            value: locale
        }
    })
})

if (!lang.value) {
    lang.value = 'hr'
}
if (lang.value === 'us') {
    setLocale('en')
} else {
    setLocale('hr')
}

if (locale.value === 'en') {
    selectedLocale.value = {
        label: '',
        value: 'en'
    }
} else {
    selectedLocale.value = {
        label: '',
        value: 'hr'
    }
}

const toggleMenu = () => {
    isOpened.value = !isOpened.value
}

const scrollToElement = (elementId: string) => {
    isOpened.value = false
    let scrollDiv = document.getElementById(elementId)?.offsetTop || 0;
    window.scrollTo({ top: scrollDiv - 156, behavior: 'smooth' });
}

const changeLocale = (code: { label: string, value: string }) => {
    lang.value = code.value
    if (code.value === 'us') {
        setLocale('en')
    } else if (code.value === 'rs' || code.value === 'ba' || code.value === 'mk') {
        setLocale('hr')
    } else {
        setLocale(code.value)
    }
}
</script>

<template>
    <header class="header">
        <div class="header__inner">
            <div class="header__inner-left">
                <div @click="scrollToElement('home')">
                    <SvgoLogo />
                </div>
            </div>
            <div class="header__inner-center">
                <p v-for="(link, index) in links" :key="index" @click="scrollToElement(link.link)">{{ link.label }}</p>
            </div>
            <div class="header__inner-right">
                <UiSelect class="custom-select" :options="availableLocales" v-model="selectedLocale" @update:model-value="changeLocale">
                        <div class="flex items-center gap-2">
                            <SvgoGb v-if="lang === 'en'" class="w-5" />
                            <SvgoUs v-if="lang === 'us'" class="w-5" />
                            <SvgoHr v-if="lang === 'hr'" class="w-5" />
                            <SvgoBa v-if="lang === 'ba'" class="w-5" />
                            <SvgoRs v-if="lang === 'rs'" class="w-5" />
                            <SvgoMk v-if="lang === 'mk'" class="w-5" />
                        </div>
                        <template #option="{ option }">
                            <div class="flex gap-2 items-center">
                                <SvgoGb v-if="option.value === 'en'" class="w-5" />
                                <SvgoUs v-if="option.value === 'us'" class="w-5" />
                                <SvgoHr v-if="option.value === 'hr'" class="w-5" />
                                <SvgoBa v-if="option.value === 'ba'" class="w-5" />
                                <SvgoRs v-if="option.value === 'rs'" class="w-5" />
                                <SvgoMk v-if="option.value === 'mk'" class="w-5" />
                            </div>
                        </template>
                </UiSelect>
                <div class="hamburger" :class="{ 'hamburger-active': isOpened }" @click="toggleMenu">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <UiButton @click="scrollToElement('contact')" class="whitespace-nowrap">{{ $t('action.contact') }}</UiButton>
            </div>
        </div>
        <Transition name="slide-fade">
            <div class="header-responsive" v-if="isOpened">
                <p v-for="(link, index) in links" :key="index" @click="scrollToElement(link.link)">{{ link.label }}</p>
                <UiButton class="mt-6" @click="scrollToElement('contact')">{{ $t('action.contact') }}</UiButton>
            </div>
        </Transition>
    </header>
</template>

<style lang="scss" scoped>
.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(250px);
}

.header {
    @apply bg-white px-4 fixed top-0 left-0 right-0 z-50;

    &__inner {
        @apply flex justify-between items-center max-w-7xl mx-auto py-6;

        &-left {
            @apply flex flex-1 justify-start;
            svg {
                @apply cursor-pointer max-w-[127px] w-full block;
            }
        }

        &-center {
            flex: 0 0 auto;
            @apply hidden md:flex gap-8;

            p {
                @apply cursor-pointer text-base font-medium text-[color:var(--primary)] hover:text-[color:var(--secondary)] duration-300;
            }
        }

        &-right {
            @apply flex flex-1 justify-end items-center gap-4;
            .custom-select {
                @apply z-10 w-fit;
                :deep().ui-select-option {
                    @apply w-full bg-transparent border-0;
                }
            }
            .ui-btn {
                @apply hidden md:block;
            }
        }

        .hamburger {
            @apply cursor-pointer block md:hidden z-10;

            &:hover {
                span {
                    @apply bg-[color:var(--secondary)];
                }
            }

            &-active {
                span {
                    background-color: var(--secondary) !important;

                    &:nth-child(1) {
                        @apply -mb-[2px] rotate-45;
                    }

                    &:nth-child(2) {
                        @apply opacity-0 -mb-[2px] -translate-x-10 pointer-events-none;
                    }

                    &:nth-child(3) {
                        @apply mb-0 w-4 -rotate-45;
                    }
                }

                &:hover {
                    span {
                        @apply bg-[color:var(--secondary)];
                    }
                }
            }

            span {
                @apply block h-[2px] rounded-full w-4 mb-1 bg-gray-500 opacity-100 duration-300 last:mb-0;
            }
        }
    }

    &-responsive {
        @apply block md:hidden fixed top-0 right-0 bottom-0 bg-white w-full max-w-[250px] px-4 py-20;

        p {
            @apply cursor-pointer block text-base font-medium mb-2 last:mb-0 text-[color:var(--primary)] hover:text-[color:var(--secondary)] duration-300;
        }
    }
}
</style>