<script setup lang="ts">
import { useValidation } from 'vue3-form-validation'
const { t } = useI18n()

const links = computed(() => {
    return [
    { label: t("footer.about"), link: 'about' },
    { label: t("footer.features"), link: 'features' },
    { label: t("footer.pricing"), link: 'pricing' },
]
})

const { form, validateFields, resetFields } = useValidation({
    email: {
        $value: '',
        $rules: [
            (email: string) => {
                if (!email) {
                    return t('error.emailRequired')
                } else if (!email.match(/\S+@\S+\.\S+/)) {
                    return 'Invalid email format'
                }
            }
        ]
    }
})

const scrollToElement = (elementId: string) => {
    let scrollDiv = document.getElementById(elementId)?.offsetTop || 0;
    window.scrollTo({ top: scrollDiv-156, behavior: 'smooth'});
}

const subscribe = async () => {
    await validateFields().then(async () => {
        await $fetch("/api/newsletter", {
            method: "POST",
            body: {
                email: form.email.$value
            },
        });
        await resetFields()
        useNuxtApp().$toast.success(t('notification.newsletter'))
    }).catch((err: Error) => {})
}
</script>

<template>
    <footer class="footer">
        <div class="footer__inner">
            <div class="footer__inner-top">
                <div class="flex flex-1 justify-start">
                    <SvgoLogo class="logo" />
                </div>
                <div class="links">
                    <p v-for="(link, index) in links" :key="index" @click="scrollToElement(link.link)">{{ link.label }}</p>
                </div>
                <div class="newsletter">
                    <UiFormItem :error-message="form.email.$errors">
                        <UiInput :placeholder="$t('footer.enterMail')" v-model="form.email.$value">
                            <template #icon-left>
                                <SvgoEnvelope class="w-5 text-gray-500" />
                            </template>
                        </UiInput>
                    </UiFormItem>
                    <UiButton @click="subscribe">{{$t('footer.subscribe')}}</UiButton>
                </div>
            </div>
            <div class="footer__inner-bottom">
                <div class="flex gap-4 items-center text-gray-500">
                    <a href="https://www.facebook.com/profile.php?id=61558758126765" target="_blank"><SvgoFacebook class="h-5" /></a>
                    <a href="https://twitter.com/vozi_lo" target="_blank"><SvgoTwitter class="h-5" /></a>
                    <a href="https://www.tiktok.com/@vozi.lo" target="_blank"><SvgoTiktok class="h-5" /></a>
                    <a href="https://www.instagram.com/vozi.lo/" target="_blank"><SvgoInstagram class="h-5" /></a>
                </div>
                <p class="text-base">© {{ new Date().getFullYear() }} vozi.lo powered by <a href="https://www.qedcode.io/" target="_blank">QED Ltd</a></p>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
.footer {
    @apply px-4 my-16 max-w-full;
    &__inner {
        @apply max-w-7xl mx-auto;
        &-top {
            @apply flex flex-col lg:flex-row lg:justify-between lg:items-center gap-6;
            .logo {
                @apply max-w-[125px];
            }
            .links {
                flex: 0 0 auto;
                @apply flex gap-8 text-base text-gray-500 font-normal;
                p {
                    @apply cursor-pointer text-base hover:text-[color:var(--secondary)] duration-300;
                }
            }
            .newsletter {
                @apply flex lg:flex-1 lg:justify-end;
                :deep().ui-input {
                    @apply rounded-tr-none rounded-br-none h-11;
                }
                .ui-btn {
                    @apply rounded-tl-none rounded-bl-none;
                }
            }
        }
        &-bottom {
            @apply border-t border-gray-200 mt-8 pt-8 flex gap-6 flex-col md:flex-row md:justify-between md:items-center;
        }
    }
}
</style>